<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-sxy">
        <div class="data clst" v-for="(item,index) in rowsData" :key="item.title">
          <h3>{{item.title}}</h3>
          <ul :class="index==0?'custom':'custom1'">
            <li :class="ele.selected ? 'selected' : ''" @click="setCurrentSelected(ele)" v-for="ele in item.arr" :key="ele.ResultID">
              <ol class="td_name">
                {{
                  ele.label
                }}
              </ol>
              <ol class="td_rate">
                {{
                 ele.Odds
                }}
              </ol>
              <ol style="width: 200px" class="td_balls">
                <div v-for="ball in ele.balls" :key="ball" :class="colorArr[Number(ball)-1]+ 'ball ' + 'b' + ball">
                  {{ball}}
                </div>

              </ol>

              <ol class="td_cash">
                <input v-model="ele.money" @click.stop="showOrHideCheck($event, ele)" type="text" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>

      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { texiaoData, weishuData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'yxws',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        {
          title: "一肖",
          arr: [],
        },
        {
          title: "尾数",
          arr: [],
        },
      ],
      labelArr1: [
        "鼠",
        "牛",
        "虎",
        "兔",
        "龙",
        "蛇",
        "马",
        "羊",
        "猴",
        "鸡",
        "狗",
        "猪",
      ],
      labelArr2: [
        "0尾",
        "1尾",
        "2尾",
        "3尾",
        "4尾",
        "5尾",
        "6尾",
        "7尾",
        "8尾",
        "9尾",
      ],
      colorArr1: [
        ["blue", "blue", "green", "green"],
        ["red", "blue", "blue", "green"],
        ["red", "red", "blue", "blue", "green"],
        ["red", "red", "blue", "blue"],
        ["green", "red", "red", "blue"],
        ["blue", "green", "red", "red"],
        ["blue", "green", "green", "red"],
        ["red", "blue", "green", "green"],
        ["red", "red", "blue", "green"],
        ["green", "red", "red", "blue"],
        ["green", "red", "red", "blue"],
        ["blue", "green", "green", "red"],
      ],
      colorArr2: [
        ["blue", "blue", "red", "red"],
        ["red", "green", "green", "blue", "blue"],
        ["red", "red", "green", "green", "blue"],
        ["blue", "red", "red", "green", "green"],
        ["blue", "blue", "red", "red", "green"],
        ["green", "blue", "blue", "red", "red"],

        ["green", "green", "blue", "blue", "red"],
        ["red", "green", "green", "blue", "blue"],
        ["red", "red", "green", "green", "blue"],
        ["blue", "red", "red", "green", "green"],
      ],
      ballsArr1: [
        ["03", "15", "27", "39"],
        ["02", "14", "26", "38"],
        ["01", "13", "25", "37", "49"],
        ["12", "24", "36", "48"],
        ["11", "23", "35", "47"],
        ["10", "22", "34", "46"],
        ["09", "21", "33", "45"],
        ["08", "20", "32", "44"],
        ["07", "19", "31", "43"],
        ["06", "18", "30", "42"],
        ["05", "17", "29", "41"],
        ["04", "16", "28", "40"],
      ],
      ballsArr2: [
        ["10", "20", "30", "40"],
        ["01", "11", "21", "31", "41"],
        ["02", "12", "22", "32", "42"],
        ["03", "13", "23", "33", "43"],
        ["04", "14", "24", "34", "44"],
        ["05", "15", "25", "35", "45"],

        ["06", "16", "26", "36", "46"],
        ["07", "17", "27", "37", "47"],
        ["08", "18", "28", "38", "48"],
        ["09", "19", "29", "39", "49"],
      ],
    };
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let arr1 = this.filterData(resAArr, 718, 729, this.labelArr1);

        arr1.forEach((element, index) => {
          element.balls = this.ballArr[index];
          element.colors = this.colorArr1[index];
        });
        this.rowsData[0].arr = arr1;
        let arr2 = this.filterData(resAArr, 730, 739, this.labelArr2);

        arr2.forEach((element, index) => {
          element.balls = this.ballsArr2[index];
          element.colors = this.colorArr2[index];
        });
        this.rowsData[1].arr = arr2;

        this.$forceUpdate();
      },
    },
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul.custom1 {
  height: 170px;
  display: flex;
  flex-direction: column;
}
.listmain > .data > ul.custom {
  height: 204px;
  display: flex;
  flex-direction: column;
}

.listmain > .data > ul > li {
  width: 50%;
  height: 34px;
}
</style>
